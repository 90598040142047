import {
  SentimentScore,
  SentimentScoreUserHitsResponseModel,
  sentimentAPI,
} from '@capturi/api-sentiment'
import {
  toFilterSearchParams,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import { DataGrid, DataGridColumn } from 'components/DataGrid'
import React from 'react'

import { useStore } from '@tanstack/react-store'
import NoDataText from '../../shared/components/NoDataText'
import UserBreakdownSkeleton from '../../shared/components/UserBreakdownSkeleton'
import SpeakerContext from '../contexts/SpeakerContext'
import { plottableUsersState } from '../data/state'
import useUserScoreStats, { UserData } from '../data/useUserScoreStats'
import { Event, logEvent } from '../events'

const hasHits = (d: UserData): boolean =>
  d.segments.some((x) => x.data?.hits && x.data.hits > 0)

const formatHitRate = (value?: number): string => {
  if (value === undefined) return ''
  return i18n.number(value, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: value < 0.01 ? 1 : 0,
  })
}

const UserHits: React.FC<{
  sentimentScore: SentimentScore
}> = ({ sentimentScore }) => {
  const speaker = React.useContext(SpeakerContext)
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useConversationsDrawer()
  const { periodDef } = useFilterPeriodContext()

  const selectedUsers = useStore(plottableUsersState)

  const { segments, isLoading } =
    useFetchSegments<SentimentScoreUserHitsResponseModel>(() =>
      sentimentAPI.getSentimentScoreUserHits(speaker, sentimentScore),
    )

  const rowData = useUserScoreStats(segments)
  const segmentsCount = states.length

  const handleViewConversations = React.useCallback(
    (valueIndex: number, userUid: string): void => {
      logEvent(
        Event.User_ViewSentimentScoreConversations,
        speaker,
        sentimentScore,
      )
      const state = states[valueIndex].values
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...state,
              userUids: [userUid],
              sentiment: {
                score: sentimentScore,
                speaker,
              },
            },
            periodDef,
          ),
        initialTab: 'sentiment',
      })
    },
    [speaker, sentimentScore, states, periodDef, openConversationsDrawer],
  )

  const handleSelectRows = React.useCallback(
    (rows: UserData[]) => {
      const users = rows.map((x) => x.user.uid)
      plottableUsersState.setState(() => users)

      logEvent(Event.User_PlotTimeSeries, speaker, sentimentScore, {
        userPlotCount: users.length,
        segmentCount: segmentsCount,
      })
    },
    [speaker, sentimentScore, segmentsCount],
  )

  const columns = React.useMemo(() => {
    const columns: DataGridColumn<UserData>[] = [
      {
        type: 'user',
        getValue: (d) => d.user.uid,
        width: 120,
      },
      {
        type: 'value',
        id: 'hit-rate',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        range: [0, 1],
      },
      {
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { hits = 0, conversations = 0 } = s.data ?? {}
          return {
            value: hits,
            total: conversations,
          }
        },
      },
      {
        type: 'button',

        render: (row, segmentIndex) => (
          <PlaySnippetsButton
            label={t`View conversations`}
            onClick={() => handleViewConversations(segmentIndex, row.user.uid)}
          />
        ),
      },
    ]
    return columns
  }, [handleViewConversations])

  const initialSelectedRowsRef = React.useRef(() => {
    return rowData.reduce<Record<string, boolean>>((memo, row, i) => {
      if (selectedUsers.indexOf(row.user.uid) !== -1) {
        memo[i] = true
      }
      return memo
    }, {})
  })

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={rowData}
        columns={columns}
        selectableRows
        initialSelectedRows={initialSelectedRowsRef.current}
        onSelectedRowsChange={handleSelectRows}
        isRowSelectable={hasHits}
      >
        <NoDataText show={!isLoading && rowData.length === 0} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
}

export default UserHits
